import React, { ReactElement } from "react";
import colors from "@styles/variables/comparepeoratesVariables.module.scss";
import styles from "./styles.module.scss";

import OfferBetterEmployeeIcon from "../icons/offerBetterEmployeeIcon";
import SaveMoneyIcon from "../icons/saveMoneyIcon";
import MaintainPayrollComplianceIcon from "../icons/maintainPayrollComplianceIcon";
import SaveTimeIcon from "../icons/saveTimeIcon";
import WhyUSImage from "../whyusImage";

const whyUsList = [
    {
        title: "Offer Better Employee Benefits and a Smoother HR Experience",
        description:
            "As a small to mid-sized business, it can be hard to compete for the best talent if you can’t offer competitive salaries or benefits—especially since more and more companies are increasing their benefits, according to the Society for Human Resource Management (SHRM).",
        icon: <OfferBetterEmployeeIcon />,
    },
    {
        title: "Save Money",
        description:
            "Another benefit of PEOs co-employing so many workers is that they’re often able to negotiate better rates with insurance companies and other benefits providers.",
        icon: <SaveMoneyIcon />,
    },
    {
        title: "Maintain payroll compliance",
        description:
            "Federal and state employment laws are complex and constantly changing, and if you don’t follow them, you may have to pay costly penalties. The IRS estimates that a whopping 40% of small to mid-sized businesses pay payroll penalties each year—and it’s possible the increase in remote work will cause that number to grow as more employees are hired and/or move out of state.",
        icon: <MaintainPayrollComplianceIcon />,
    },
    {
        title: "save time",
        description:
            "Let’s face it: you and your HR team have better things to do than file paperwork, negotiate with benefits providers, and sift through ever-changing employer-related laws. While the cost savings are important, another big benefit of using a PEO is freeing up time to focus on growing your company.",
        icon: <SaveTimeIcon />,
    },
];

export default function WhyUs(): ReactElement {
    return (
        <section
            className={`mt-10 lg:mt-16 lg:flex lg:flex-row-reverse lg:justify-between mx-auto ${styles["whyUsWrapper"]}`}
        >
            <div>
                <h2
                    style={{ color: "black" }}
                    className={`mb-14 bold ${styles["mainTitle"]}`}
                >
                    <b>Benefits of a PEO for Small Businesses and Startups</b>
                </h2>
                <div className="lg:flex lg:flex-wrap lg:justify-center">
                    {whyUsList.map((item, index) => (
                        <div
                            key={index}
                            className={`max-w-xs mx-auto lg:mx-0 lg:max-w-none mb-20 w-full lg:flex ${styles["item"]}`}
                        >
                            <div className={`lg:mr-12 ${styles["icon"]}`}>
                                {item.icon}
                            </div>
                            <div>
                                <h3
                                    style={{ color: "black" }}
                                    className="font-medium text-base  lg:text-xl "
                                >
                                    {item.title}
                                </h3>
                                <p
                                    style={{
                                        color: colors.whyUsBriefColor,
                                    }}
                                    className="font-light text-sm md:text-base mt-2"
                                >
                                    {item.description}
                                </p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div className={`${styles["whyUsImage"]}`}>
                <WhyUSImage />
            </div>
        </section>
    );
}
