import React, { ReactElement } from "react";

export default function SaveTimeIcon(): ReactElement {
    return (
        <svg width="101" height="100" viewBox="0 0 101 100">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M43.7 85.4697C66.63 85.4697 85.4733 66.6272 85.4733 43.6997C85.4733 20.658 66.63 1.92969 43.7 1.92969C20.6566 1.92969 1.92664 20.658 1.92664 43.6997C1.92664 66.6272 20.6566 85.4697 43.7 85.4697V85.4697Z"
                fill="#88C6DF"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M43.7 78.4346C62.8842 78.4346 78.4359 62.8838 78.4359 43.7021C78.4359 24.5205 62.8842 8.85547 43.7 8.85547C24.5158 8.85547 8.85083 24.5221 8.85083 43.7021C8.85083 62.8821 24.5175 78.4346 43.7 78.4346Z"
                fill="white"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M76.8458 98.0689C88.5383 98.0689 98.0733 88.5338 98.0733 76.843C98.0733 65.2655 88.5383 55.7305 76.8458 55.7305C65.2675 55.7305 55.7325 65.2655 55.7325 76.843C55.7325 88.5338 65.2675 98.0689 76.8458 98.0689Z"
                fill="#69C8C3"
            />
            <path
                d="M43.7017 87.4008C19.6042 87.4008 0 67.7958 0 43.7C0.00183723 33.5034 3.57276 23.6291 10.0933 15.79C10.2541 15.5905 10.4529 15.4251 10.6783 15.3032C10.9036 15.1814 11.151 15.1057 11.4059 15.0804C11.6608 15.0552 11.9182 15.0809 12.1631 15.1562C12.4079 15.2314 12.6354 15.3547 12.8321 15.5187C13.0289 15.6828 13.191 15.8844 13.309 16.1117C13.4271 16.3391 13.4987 16.5877 13.5196 16.843C13.5406 17.0983 13.5106 17.3552 13.4312 17.5988C13.3519 17.8424 13.2248 18.0677 13.0575 18.2617C7.11424 25.4067 3.85885 34.4063 3.85583 43.7C3.85583 65.6692 21.7317 83.5408 43.7017 83.5408C46.0165 83.5456 48.3264 83.3291 50.6 82.8942C50.8488 82.8464 51.1046 82.8481 51.3527 82.8992C51.6009 82.9503 51.8365 83.0497 52.0462 83.1919C52.2559 83.334 52.4356 83.5161 52.5749 83.7277C52.7143 83.9392 52.8106 84.1762 52.8583 84.425C52.9061 84.6738 52.9044 84.9296 52.8533 85.1777C52.8022 85.4259 52.7028 85.6615 52.5606 85.8712C52.4185 86.0809 52.2364 86.2606 52.0248 86.3999C51.8133 86.5393 51.5763 86.6356 51.3275 86.6833C48.8142 87.1651 46.2607 87.4054 43.7017 87.4008ZM84.795 52.8933C84.6732 52.8929 84.5516 52.882 84.4317 52.8608C83.9284 52.7651 83.4838 52.4734 83.1957 52.0499C82.9075 51.6264 82.7994 51.1057 82.895 50.6025C83.3296 48.3274 83.547 46.0162 83.5442 43.7C83.5442 21.7317 65.6683 3.85917 43.7017 3.85917C34.4067 3.86255 25.4059 7.11783 18.2592 13.0608C18.0652 13.2282 17.8399 13.3552 17.5963 13.4345C17.3527 13.5139 17.0958 13.5439 16.8405 13.523C16.5851 13.502 16.3366 13.4304 16.1092 13.3124C15.8819 13.1943 15.6803 13.0322 15.5162 12.8355C15.3522 12.6387 15.2289 12.4113 15.1537 12.1664C15.0784 11.9215 15.0527 11.6641 15.0779 11.4092C15.1032 11.1543 15.1789 10.9069 15.3007 10.6816C15.4226 10.4562 15.588 10.2574 15.7875 10.0967C23.6284 3.57583 33.5036 0.00391003 43.7017 0C67.7992 0 87.4 19.605 87.4 43.7C87.4053 46.2588 87.167 48.8122 86.6883 51.3258C86.6041 51.7664 86.369 52.1639 86.0235 52.45C85.6779 52.736 85.2436 52.8928 84.795 52.8933Z"
                fill="#1E2C55"
            />
            <path
                d="M43.7017 49.3768C42.5787 49.3779 41.4806 49.046 40.5463 48.4229C39.612 47.7999 38.8835 46.9137 38.453 45.8765C38.0224 44.8393 37.9092 43.6978 38.1275 42.5962C38.3459 41.4947 38.8861 40.4826 39.6798 39.6882C40.4734 38.8937 41.4849 38.3525 42.5862 38.1329C43.6875 37.9134 44.8292 38.0255 45.8669 38.455C46.9045 38.8845 47.7914 39.6121 48.4154 40.5457C49.0394 41.4794 49.3725 42.5771 49.3725 43.7001C49.3712 45.2042 48.7735 46.6465 47.7104 47.7106C46.6474 48.7747 45.2058 49.3739 43.7017 49.3768V49.3768ZM43.7017 41.8843C43.3423 41.8839 42.9908 41.9902 42.6918 42.1897C42.3929 42.3891 42.1597 42.6728 42.022 43.0048C41.8843 43.3367 41.8481 43.7021 41.9181 44.0546C41.9881 44.4072 42.161 44.731 42.4151 44.9852C42.6692 45.2394 42.993 45.4125 43.3455 45.4827C43.698 45.5528 44.0634 45.5168 44.3954 45.3792C44.7274 45.2417 45.0112 45.0087 45.2108 44.7098C45.4104 44.4109 45.5168 44.0595 45.5167 43.7001C45.51 43.2207 45.3167 42.7629 44.9777 42.4238C44.6388 42.0847 44.181 41.8912 43.7017 41.8843V41.8843Z"
                fill="#1E2C55"
            />
            <path
                d="M43.7016 41.8841C43.4482 41.8842 43.1973 41.8343 42.9633 41.7374C42.7292 41.6404 42.5166 41.4982 42.3375 41.3189C42.1585 41.1396 42.0166 40.9268 41.9199 40.6926C41.8232 40.4584 41.7737 40.2075 41.7741 39.9541V16.1208C41.7688 15.8642 41.8147 15.6092 41.9092 15.3706C42.0037 15.132 42.1448 14.9147 42.3244 14.7314C42.5039 14.5481 42.7183 14.4024 42.9548 14.303C43.1914 14.2036 43.4454 14.1523 43.702 14.1523C43.9586 14.1523 44.2126 14.2036 44.4492 14.303C44.6858 14.4024 44.9001 14.5481 45.0796 14.7314C45.2592 14.9147 45.4004 15.132 45.4948 15.3706C45.5893 15.6092 45.6353 15.8642 45.6299 16.1208V39.9541C45.6303 40.2075 45.5806 40.4585 45.4838 40.6927C45.3871 40.9269 45.2451 41.1397 45.066 41.319C44.8869 41.4982 44.6742 41.6404 44.4401 41.7374C44.2059 41.8343 43.955 41.8842 43.7016 41.8841V41.8841Z"
                fill="#1E2C55"
            />
            <path
                d="M29.9633 59.364C29.5818 59.3638 29.2088 59.2505 28.8916 59.0384C28.5745 58.8263 28.3273 58.525 28.1813 58.1724C28.0353 57.8199 27.9971 57.432 28.0714 57.0578C28.1458 56.6835 28.3295 56.3397 28.5992 56.0698L39.725 44.9448C40.0886 44.5912 40.5768 44.3949 41.084 44.3985C41.5912 44.402 42.0766 44.6051 42.4352 44.9637C42.7939 45.3224 42.9969 45.8078 43.0005 46.315C43.004 46.8222 42.8078 47.3104 42.4541 47.674L31.3283 58.799C31.1495 58.9788 30.9368 59.1213 30.7024 59.2183C30.4681 59.3153 30.2169 59.3648 29.9633 59.364Z"
                fill="#1E2C55"
            />
            <path
                d="M43.7016 10.784C43.4482 10.7842 43.1973 10.7343 42.9633 10.6373C42.7292 10.5403 42.5166 10.3981 42.3375 10.2189C42.1585 10.0396 42.0166 9.82676 41.9199 9.59257C41.8232 9.35837 41.7737 9.10741 41.7741 8.85405V8.17155C41.7688 7.915 41.8147 7.65996 41.9092 7.42139C42.0037 7.18281 42.1448 6.96549 42.3244 6.78217C42.5039 6.59884 42.7183 6.4532 42.9548 6.35377C43.1914 6.25434 43.4454 6.20312 43.702 6.20312C43.9586 6.20312 44.2126 6.25434 44.4492 6.35377C44.6858 6.4532 44.9001 6.59884 45.0796 6.78217C45.2592 6.96549 45.4004 7.18281 45.4948 7.42139C45.5893 7.65996 45.6353 7.915 45.6299 8.17155V8.85405C45.6303 9.10745 45.5806 9.35843 45.4838 9.59263C45.3871 9.82683 45.2451 10.0396 45.066 10.2189C44.8869 10.3982 44.6742 10.5404 44.4401 10.6373C44.2059 10.7343 43.955 10.7842 43.7016 10.784V10.784Z"
                fill="#1E2C55"
            />
            <path
                d="M43.7016 81.1583C43.4483 81.1584 43.1975 81.1085 42.9635 81.0116C42.7294 80.9147 42.5168 80.7725 42.3378 80.5934C42.1588 80.4142 42.0168 80.2015 41.9201 79.9674C41.8234 79.7333 41.7738 79.4824 41.7741 79.2291V78.4333C41.7688 78.1767 41.8147 77.9217 41.9092 77.6831C42.0037 77.4445 42.1448 77.2272 42.3244 77.0439C42.5039 76.8606 42.7183 76.7149 42.9548 76.6155C43.1914 76.5161 43.4454 76.4648 43.702 76.4648C43.9586 76.4648 44.2126 76.5161 44.4492 76.6155C44.6858 76.7149 44.9001 76.8606 45.0796 77.0439C45.2592 77.2272 45.4004 77.4445 45.4948 77.6831C45.5893 77.9217 45.6353 78.1767 45.6299 78.4333V79.2291C45.6301 79.4824 45.5804 79.7333 45.4836 79.9674C45.3868 80.2015 45.2448 80.4142 45.0657 80.5934C44.8866 80.7726 44.6739 80.9147 44.4399 81.0116C44.2058 81.1085 43.9549 81.1584 43.7016 81.1583V81.1583Z"
                fill="#1E2C55"
            />
            <path
                d="M79.2301 45.6287H78.4367C77.9319 45.6182 77.4513 45.4103 77.098 45.0496C76.7447 44.6889 76.5469 44.204 76.5469 43.6991C76.5469 43.1942 76.7447 42.7094 77.098 42.3487C77.4513 41.9879 77.9319 41.78 78.4367 41.7695H79.2301C79.7349 41.78 80.2155 41.9879 80.5688 42.3487C80.9221 42.7094 81.1199 43.1942 81.1199 43.6991C81.1199 44.204 80.9221 44.6889 80.5688 45.0496C80.2155 45.4103 79.7349 45.6182 79.2301 45.6287V45.6287Z"
                fill="#1E2C55"
            />
            <path
                d="M8.85009 45.6287H8.16675C7.66194 45.6182 7.18134 45.4103 6.82803 45.0496C6.47473 44.6889 6.27686 44.204 6.27686 43.6991C6.27686 43.1942 6.47473 42.7094 6.82803 42.3487C7.18134 41.9879 7.66194 41.78 8.16675 41.7695H8.84759C9.3524 41.78 9.833 41.9879 10.1863 42.3487C10.5396 42.7094 10.7375 43.1942 10.7375 43.6991C10.7375 44.204 10.5396 44.6889 10.1863 45.0496C9.833 45.4103 9.3524 45.6182 8.84759 45.6287H8.85009Z"
                fill="#434A53"
            />
            <path
                d="M76.8451 78.7727C71.9451 78.7727 68.1084 75.9811 68.1084 72.4169C68.1084 68.9111 72.0251 66.0586 76.8451 66.0586C81.7492 66.0586 85.5851 68.8528 85.5851 72.4169C85.5904 72.6734 85.5445 72.9285 85.45 73.1671C85.3555 73.4056 85.2143 73.6229 85.0348 73.8063C84.8552 73.9896 84.6409 74.1352 84.4043 74.2347C84.1678 74.3341 83.9138 74.3853 83.6571 74.3853C83.4005 74.3853 83.1465 74.3341 82.91 74.2347C82.6734 74.1352 82.4591 73.9896 82.2795 73.8063C82.1 73.6229 81.9588 73.4056 81.8643 73.1671C81.7698 72.9285 81.7239 72.6734 81.7292 72.4169C81.7292 71.3727 79.8717 69.9169 76.8451 69.9169C73.9117 69.9169 71.9642 71.4169 71.9642 72.4169C71.9642 73.5961 74.0526 74.9169 76.8451 74.9169C77.3499 74.9274 77.8305 75.1353 78.1838 75.496C78.5371 75.8567 78.735 76.3415 78.735 76.8465C78.735 77.3514 78.5371 77.8362 78.1838 78.1969C77.8305 78.5576 77.3499 78.7655 76.8451 78.776V78.7727Z"
                fill="#1E2C55"
            />
            <path
                d="M76.845 87.7399C72.0267 87.7399 68.1083 84.8891 68.1083 81.3841C68.103 81.1275 68.1489 80.8725 68.2434 80.6339C68.3379 80.3953 68.479 80.178 68.6586 79.9947C68.8382 79.8114 69.0525 79.6657 69.2891 79.5663C69.5256 79.4669 69.7796 79.4156 70.0362 79.4156C70.2929 79.4156 70.5469 79.4669 70.7834 79.5663C71.02 79.6657 71.2343 79.8114 71.4139 79.9947C71.5934 80.178 71.7346 80.3953 71.8291 80.6339C71.9236 80.8725 71.9695 81.1275 71.9642 81.3841C71.9642 82.3766 73.9117 83.8841 76.845 83.8841C79.8717 83.8841 81.7292 82.4291 81.7292 81.3841C81.7292 80.1499 79.7233 78.7732 76.845 78.7732C76.3402 78.7627 75.8596 78.5548 75.5063 78.1941C75.153 77.8334 74.9551 77.3486 74.9551 76.8436C74.9551 76.3387 75.153 75.8539 75.5063 75.4932C75.8596 75.1325 76.3402 74.9246 76.845 74.9141C81.7492 74.9141 85.585 77.7566 85.585 81.3841C85.5833 84.9482 81.75 87.7399 76.845 87.7399Z"
                fill="#1E2C55"
            />
            <path
                d="M76.845 69.9163C76.5917 69.9164 76.3408 69.8666 76.1067 69.7697C75.8727 69.6728 75.66 69.5307 75.4809 69.3515C75.3018 69.1723 75.1598 68.9596 75.063 68.7255C74.9662 68.4914 74.9165 68.2405 74.9167 67.9872V64.2497C74.9113 63.9931 74.9573 63.7381 75.0518 63.4995C75.1462 63.2609 75.2874 63.0436 75.467 62.8603C75.6465 62.677 75.8608 62.5313 76.0974 62.4319C76.334 62.3325 76.588 62.2813 76.8446 62.2812C77.1012 62.2813 77.3552 62.3325 77.5918 62.4319C77.8283 62.5313 78.0427 62.677 78.2222 62.8603C78.4018 63.0436 78.5429 63.2609 78.6374 63.4995C78.7319 63.7381 78.7778 63.9931 78.7725 64.2497V67.9938C78.7714 68.5042 78.5678 68.9933 78.2064 69.3538C77.8451 69.7142 77.3554 69.9166 76.845 69.9163V69.9163Z"
                fill="#1E2C55"
            />
            <path
                d="M76.845 91.4883C76.5916 91.4884 76.3407 91.4385 76.1066 91.3415C75.8724 91.2446 75.6597 91.1024 75.4806 90.9231C75.3015 90.7439 75.1595 90.531 75.0628 90.2968C74.966 90.0626 74.9163 89.8117 74.9167 89.5583V85.8083C74.9113 85.5517 74.9573 85.2967 75.0518 85.0581C75.1462 84.8195 75.2874 84.6022 75.467 84.4189C75.6465 84.2356 75.8608 84.0899 76.0974 83.9905C76.334 83.8911 76.588 83.8398 76.8446 83.8398C77.1012 83.8398 77.3552 83.8911 77.5918 83.9905C77.8283 84.0899 78.0427 84.2356 78.2222 84.4189C78.4018 84.6022 78.5429 84.8195 78.6374 85.0581C78.7319 85.2967 78.7778 85.5517 78.7725 85.8083V89.5583C78.7729 89.8116 78.7234 90.0626 78.6267 90.2968C78.53 90.531 78.3881 90.7438 78.2091 90.9231C78.03 91.1023 77.8174 91.2445 77.5833 91.3415C77.3493 91.4385 77.0984 91.4884 76.845 91.4883V91.4883Z"
                fill="#1E2C55"
            />
            <path
                d="M76.845 99.9983C64.1392 99.9983 53.8033 89.6108 53.8033 76.8433C53.8033 64.1383 64.1367 53.8008 76.845 53.8008C81.5767 53.8008 86.2033 55.2174 89.8717 57.7799C90.0796 57.9253 90.2569 58.1102 90.3933 58.3241C90.5298 58.538 90.6228 58.7767 90.667 59.0265C90.7112 59.2763 90.7058 59.5324 90.6511 59.7802C90.5963 60.0279 90.4933 60.2624 90.3479 60.4704C90.2025 60.6783 90.0176 60.8555 89.8037 60.992C89.5899 61.1285 89.3512 61.2215 89.1013 61.2657C88.8515 61.3099 88.5954 61.3045 88.3477 61.2498C88.1 61.195 87.8654 61.092 87.6575 60.9466C84.635 58.8291 80.795 57.6641 76.845 57.6641C66.2667 57.6641 57.6592 66.2691 57.6592 76.8466C57.6592 87.4866 66.2667 96.1424 76.845 96.1424C87.4858 96.1424 96.1442 87.4866 96.1442 76.8466C96.1442 73.1058 94.9317 69.2583 92.7275 66.0133C92.5852 65.8038 92.4856 65.5683 92.4344 65.3203C92.3831 65.0723 92.3812 64.8166 92.4288 64.5679C92.4763 64.3191 92.5724 64.0822 92.7115 63.8705C92.8506 63.6589 93.0301 63.4768 93.2396 63.3345C93.4491 63.1923 93.6846 63.0927 93.9326 63.0414C94.1806 62.9901 94.4363 62.9882 94.685 63.0358C94.9338 63.0833 95.1707 63.1794 95.3823 63.3185C95.5939 63.4577 95.7761 63.6371 95.9183 63.8466C98.5508 67.7283 100.002 72.3466 100.002 76.8466C100 89.6108 89.6133 99.9983 76.845 99.9983Z"
                fill="#1E2C55"
            />
        </svg>
    );
}
